import { makeStyles, Theme } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    minHeight: '100vh',
  },
  main: {
    flex: '1',
    width: '100%',
    display: 'flex',
    '& > *': {
      width: '100%',
    },
  },
  container: {
    paddingBottom: 60,
    maxWidth: 960,
    [theme.breakpoints.up('lg')]: {
      maxWidth: 1248,
    },
  },
}));
