import { put } from 'redux-saga/effects';
import web3 from 'helpers/getWeb3';

import ABI from 'services/contracts/TimeLockPool/ABI.json';
import { ClaimActions } from 'store/reducers/claim';
import { SnackbarActions } from 'store/reducers/snackbar';

export function* claimSaga(action: ReturnType<typeof ClaimActions.claimRequest>) {
  const { receiver, poolAddress, claimId } = action.payload;

  try {
    yield put(ClaimActions.addClaimProcessing(claimId));

    //@ts-ignore
    const tokenLockContract = yield new web3.eth.Contract(ABI, poolAddress);

    yield tokenLockContract.methods.claimRewards(receiver).send({ from: receiver });

    yield put(ClaimActions.removeClaimProcessing(claimId));
    yield put(ClaimActions.claimSuccess());
    yield put(SnackbarActions.showNotification({ type: 'success', message: 'Claim was successful' }))
  } catch (error) {
    console.log(error)
    yield put(ClaimActions.removeClaimProcessing(claimId));
    yield put(ClaimActions.claimFailure());
    yield put(SnackbarActions.showNotification({ type: 'error', message: 'Claim was failed' }))
  }
}
