import CoinGecko from 'coingecko-api'

class CoinGeckoAPIClient extends CoinGecko {
  async getEstimatedPrice(id) {
    const {
      data: { market_data: coin },
    } = await this.coins.fetch(id, {})

    return coin.current_price.usd
  }
}

const client = new CoinGeckoAPIClient()

export default client
