import React from 'react';
import { Dialog, DialogContent, Typography, Box, Button, useMediaQuery } from '@material-ui/core';

import { CoinbaseIcon, MetamaskIcon, WalletConnectIcon } from 'icons';

import Transition from 'shared/components/Transition';

import { useDialogStyles } from '../styles';

interface MetaMaskProps {
  open: boolean;
  loading: boolean;
  isMetaMask: boolean;
  handleClose(e?: any): void;
  handleButton(e?: any): void;
  handleTrustClick: () => void;
}

const MetaMaskDialog = (props: MetaMaskProps) => {
  const { open, loading, isMetaMask, handleClose, handleButton, handleTrustClick } = props;

  const dialogClasses = useDialogStyles();
  const isMobile = useMediaQuery('(max-width:960px)');

  let title = 'Install MetaMask';
  let description = 'Install MetaMask to connect to Rainmaker';
  let buttons: {
    button: string;
    icon?: () => JSX.Element;
    onClick?: (e: any) => void;
  }[] = [
    {
      button: "Go to MetaMask's website",
    },
  ];

  if (isMetaMask) {
    title = 'Select wallet';
    description = 'Connect to the site below with one of our available wallet providers.';
    buttons = [
      {
        button: 'MetaMask',
        icon: () => <MetamaskIcon />,
        onClick: handleButton,
      },
      {
        button: 'Coinbase',
        icon: () => <CoinbaseIcon />,
        onClick: handleButton,
      },
      {
        button: 'Wallet Connect',
        icon: () => <WalletConnectIcon />,
        onClick: handleTrustClick,
      },
    ];
  }

  if (loading) {
    buttons = [{ button: 'Connecting...' }];
  }

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition}>
      <Box mt={5} mb={5} pl={3} pr={3} className={dialogClasses.wrapContent}>
        <DialogContent>
          <Typography variant="h3">{title}</Typography>
          <Box mt={2} mb={2}>
            <Typography variant="subtitle1">{description}</Typography>
          </Box>
          <Box mt={5}>
            {buttons.map(({ button, icon, onClick }) => {
              return (
                <Button className={dialogClasses.walletBtn} onClick={onClick} key={button}>
                  <Typography variant="h4">{button}</Typography>
                  {icon && icon()}
                </Button>
              );
            })}
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default MetaMaskDialog;
