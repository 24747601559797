import React from 'react';

import { Dialog, DialogContent, Typography, Box, Button } from '@material-ui/core';

import Transition from 'shared/components/Transition';

import { useDialogStyles } from '../styles';

interface MetaMaskProps {
  open: boolean;
  handleClose(e?: any): void;
  handleButton(e?: any): void;
}

const WalletDialog = (props: MetaMaskProps) => {
  const { open, handleClose, handleButton } = props;

  const dialogClasses = useDialogStyles();

  return (
    <Dialog open={open} onClose={handleClose} TransitionComponent={Transition} maxWidth="xs">
      <Box mt={5} mb={5} pl={3} pr={3}>
        <DialogContent className={dialogClasses.walletContent}>
          <Box mt={2} mb={3}>
            <Typography variant="h3">Wallet is connected</Typography>
          </Box>
          <Box>
            <Button onClick={handleButton} variant="outlined">
              Disconnect
            </Button>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default WalletDialog;
