import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ClaimState } from './types';

const initialState: ClaimState = {
  loading: false,
  error: false,
  claimRewardsIds: [],
};

const { actions, reducer } = createSlice({
  name: 'claim',
  initialState,
  reducers: {
    claimRequest: (
      state,
      action: PayloadAction<{ poolAddress: string; receiver?: string; claimId: number }>,
    ) => {
      state.loading = true;
      state.error = false;
    },
    claimSuccess: (state) => {
      state.loading = false;
      state.error = false;
    },
    claimFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
    addClaimProcessing: (state, action: PayloadAction<number>) => {
      state.claimRewardsIds = state.claimRewardsIds.concat([action.payload]);
    },
    removeClaimProcessing: (state, action: PayloadAction<number>) => {
      state.claimRewardsIds = state.claimRewardsIds.filter((item) => item !== action.payload);
    },
  },
});

export { actions as ClaimActions };

export default reducer;
