import React from 'react';
import { useSelector } from 'react-redux';

import { RouteProps, Redirect } from 'react-router-dom';

import Route from 'shared/components/Route';
import { LayoutProps } from 'shared/components/Layout';

import * as routePaths from 'constants/routes';

export default function PrivateRoute(props: RouteProps & Omit<LayoutProps, 'children'>) {
  const { ...rest } = props;

  const connected = JSON.parse(localStorage.getItem('connected') as string);

  const {
    wallet: { address },
  } = useSelector((state) => state);

  if (!address && !connected) {
    return <Redirect to={routePaths.ROUTE_HOME} />;
  }

  return <Route {...rest} />;
}
