import { makeStyles, Theme } from '@material-ui/core';

export const useDialogStyles = makeStyles((theme: Theme) => ({
  walletBtn: {
    width: '100%',
    height: 70,
    padding: '16px 20px',
    borderRadius: 8,
    cursor: 'pointer',
    transition: '.3s',
    display: 'flex',
    justifyContent: 'space-between',
    background: '#f1f3f5',
    backdropFilter: 'blur(100px)',
    marginBottom: 20,
    '& svg': {
      width: 50,
      height: 50,
    },
    [theme.breakpoints.down('sm')]: {
      '& h4': {
        fontSize: 16,
      },
    },
  },
  wrapContent: {
    width: '100%',
  },
  walletContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& h3': {
      textAlign: 'center',
    },
    '& button': {
      width: '100%',
      minWidth: 335,
      [theme.breakpoints.down('sm')]: {
        minWidth: 200,
      },
    },
  },
  dialogTitle: {
    width: '100%',
    height: 70,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 22,
    borderBottom: '1px solid #ededed',
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    top: 10,
    right: 10,
    cursor: 'pointer',
  },
  processDialogs: {
    '& > div > div': {
      maxWidth: 415,
    },
  },
  proceesContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    '& h3': {
      textAlign: 'center',
    },
    '& button': {
      width: '100%',
      minWidth: 335,
      [theme.breakpoints.down('sm')]: {
        minWidth: 200,
      },
    },
    padding: '0 32px',
  },
  processText: {
    fontSize: 14,
  },
  processIcon: {
    '& > svg': {
      width: 60,
      height: 60,
    },
  },
  select: {
    marginBottom: 30,
    '& > svg': {
      top: 'calc(50% - 6px)',
    },
  },
}));
