import React, { PropsWithChildren, SyntheticEvent, useState } from 'react';
import clsx from 'clsx';
import { useDispatch, useSelector } from 'react-redux';
import { Box, Button, Menu as MenuMui, Typography } from '@material-ui/core';
import {
  KeyboardArrowDown as KeyboardArrowDownIcon,
  KeyboardArrowUp as KeyboardArrowUpIcon,
  MoreHoriz as MoreHorizIcon,
} from '@material-ui/icons';

import { ROUTE_OVERVIEW } from 'constants/routes';

import { sliceAddress } from 'helpers/address';

import MetaMaskDialog from 'shared/dialogs/MetaMask';

import { WalletActions } from 'store/reducers/wallet';

import { useStyles } from './styles';
import { useHistory } from 'react-router-dom';

interface MenuProps {
  action?: 'button' | 'typography' | 'profile';
  size?: 'small' | 'medium';
  align?: 'left' | 'right';
}

const Menu = (props: PropsWithChildren<MenuProps>) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const { children, action, size, align = 'left' } = props;

  const {
    wallet: { address },
    metamask: { isMetaMask, loading: metamaskLoading },
  } = useSelector((state) => state);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [showMetaMask, setShowMetaMask] = useState<boolean>(false);
  const isConnected = address !== '';
  const arrow = isConnected && (anchorEl ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  const handleClose = (event: SyntheticEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };

  const handleOpenMetaMask = () => {
    setShowMetaMask(true);
  };

  const handleCloseMetaMask = () => {
    setShowMetaMask(false);
  };

  const handleMetaMaskButton = () => {
    if (!isMetaMask) {
      return window.open('https://metamask.io/download.html', '_blank');
    }

    dispatch(
      WalletActions.connectWallet({
        callback: () => {
          handleCloseMetaMask();
          history.push(ROUTE_OVERVIEW);
        },
      }),
    );
  };

  const handleTrustClick = () => {
    handleCloseMetaMask();

    dispatch(WalletActions.trustConnect())
  }

  let content = (
    <Button
      variant="outlined"
      className={clsx(classes.connectBtn, {
        [classes.connectBtnPadding]: isConnected,
      })}
      onClick={isConnected ? handleClick : handleOpenMetaMask}
    >
      {isConnected ? sliceAddress(address) : 'Connect wallet'}
      {arrow}
    </Button>
  );

  if (action === 'button') {
    content = (
      <Button
        variant={anchorEl ? 'contained' : 'outlined'}
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleClick}
        classes={{ root: size === 'medium' ? classes.buttonMedium : classes.buttonSmall }}
      >
        <MoreHorizIcon
          classes={{ root: size === 'medium' ? classes.iconMedium : classes.iconSmall }}
        />
      </Button>
    );
  }

  if (action === 'typography') {
    content = (
      <Box className={classes.addressWrapper}>
        <Typography className={classes.headerAddress} onClick={handleClick}>
          {sliceAddress(address)}
        </Typography>
        {arrow}
      </Box>
    );
  }

  return (
    <>
      {content}
      <MenuMui
        id="menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        getContentAnchorEl={null}
        anchorOrigin={{ vertical: 'bottom', horizontal: align }}
        transformOrigin={{ vertical: -4, horizontal: align }}
        classes={{ paper: classes.paper, list: classes.list }}
        onClick={(e) => handleClose(e)}
        onClose={(e: SyntheticEvent) => handleClose(e)}
      >
        {children}
      </MenuMui>
      <MetaMaskDialog
        isMetaMask={isMetaMask}
        open={showMetaMask}
        handleClose={handleCloseMetaMask}
        loading={metamaskLoading}
        handleButton={handleMetaMaskButton}
        handleTrustClick={handleTrustClick}
      />
    </>
  );
};

Menu.defaultProps = {
  action: 'button',
  size: 'medium',
  align: 'left',
  disabled: false,
};

export default Menu;
