import React, { useState } from 'react';
import { Dialog, DialogContent, Typography, Box, Button, DialogTitle, CircularProgress, Select, FormControl, MenuItem } from '@material-ui/core';

import { Check, HighlightOff } from '@material-ui/icons';
import Transition from 'shared/components/Transition';

import { useDialogStyles } from '../styles';
import { useSelector } from 'react-redux';
import { CloseIcon } from 'icons';

interface StakeProps {
  open: boolean;
  handleClose: () => void;
  handleButton: (type: string) => void;
}

const BeneficiaryTypeDialog = (props: StakeProps) => {
  const { open, handleClose, handleButton } = props;

  const dialogClasses = useDialogStyles();

  const loading = useSelector(state => state.vesting.addBeneficiaryLoading);
  const error = useSelector(state => state.vesting.addBeneficiaryError);

  const [selectedOption, setSelectedOption] = useState<string>('strategic')
  const [clicked, setClicked] = useState<boolean>(false)

  let buttonLabel = 'Add beneficiary'
  let text = 'Add beneficiary request in progress';

  let stakeIcon = <CircularProgress />

  if (loading) {
    buttonLabel = 'In Progress...';
  } else if (!loading && !error) {
    buttonLabel = 'Done';
    stakeIcon = <Check color='primary' />
  } else if (!loading && error) {
    text = 'Your address is not beneficiary by the selected type';
    stakeIcon = <HighlightOff color='primary' />
  }

  const handleSubmit = () => {
    if (!loading && !error) {
      handleClose()
      return
    }

    setClicked(true)
    handleButton(selectedOption)
  }

  const options = [
    {
      label: 'Strategic',
      value: 'strategic',
    },
    {
      label: 'Private T1',
      value: 'private_t1',
    },
    {
      label: 'Private T2',
      value: 'private_t2',
    },
    {
      label: 'KOL',
      value: 'kol',
    },
    {
      label: 'Team',
      value: 'team',
    },
    {
      label: 'Advisory',
      value: 'advisory',
    },
    {
      label: 'Seed',
      value: 'seed',
    },
    {
      label: 'Pre-Seed',
      value: 'pre-seed',
    },
  ]

  const handleSelectType = (event: React.ChangeEvent<{
    name?: string | undefined;
    value: unknown;
  }>) => {
    setSelectedOption(event.target.value as string)
  }

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      TransitionComponent={Transition}
      className={dialogClasses.processDialogs}
    >
      <DialogTitle className={dialogClasses.dialogTitle}>
        <Typography variant='h3'>Beneficiary Type</Typography>
        <Box className={dialogClasses.closeIcon} onClick={handleClose}>
          <CloseIcon />
        </Box>
      </DialogTitle>
      <DialogContent className={dialogClasses.proceesContent}>
        <Box>
          <Box mt={2}>
            {/* {(!loading && !clicked || error) && (
              <>
                <Typography>Select your beneficiary type</Typography>
                <FormControl fullWidth>
                  <Select
                    labelId="beneficiary-modal-label"
                    label='Beneficiary type'
                    value={selectedOption}
                    onChange={handleSelectType}
                    className={dialogClasses.select}
                  >
                    {options.map((option) => {
                      return (
                        <MenuItem key={option.value} value={option.value}>
                          <Typography>{option.label}</Typography>
                        </MenuItem>
                      )
                    })}
                  </Select>
                </FormControl>
              </>
            )} */}
            {((loading || error) || (!loading && !error)) && (
              <Box display="flex" alignItems="center">
                <Box className={dialogClasses.processIcon}>
                  {stakeIcon}
                </Box>
                <Box mt={2} mb={3} ml={3.5}>
                  <Typography className={dialogClasses.processText}>{text}</Typography>
                </Box>
              </Box>
            )}
          </Box>
          <Box mb={5}>
            <Button onClick={handleSubmit} variant="contained" disabled={loading}>
              {buttonLabel}
            </Button>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default BeneficiaryTypeDialog;
