import { put } from 'redux-saga/effects';
import { NetworkActions } from 'store/reducers/network';
import { bscNetworks, ethNetworks, networkConstants } from '../../constants/network';

declare const window: any;

export function* changeNetworkSaga({
  payload,
}: ReturnType<typeof NetworkActions.changeNetworkRequest>) {
  try {
    try {
      yield window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [
          {
            chainId: `0x${payload.chainId.toString(16)}`,
          },
        ],
      });
    } catch (switchError) {
      //@ts-ignore
      if (switchError.code === 4902) {
        try {
           // @ts-ignore
          const network = networkConstants[+payload.chainId];
          const {
            chainId,
            chainName,
            rpcUrls,
            name,
            symbol,
            decimals,
            blockExplorerUrls,
          } = network;

          yield window.ethereum
          .request({
            method: 'wallet_addEthereumChain',
            params: [
              {
                chainId,
                chainName,
                rpcUrls,
                nativeCurrency: {
                  name,
                  symbol,
                  decimals,
                },
                blockExplorerUrls,
              },
            ],
          })
        } catch (addError) {
         throw (addError)
        }
      }
    }
    yield put(NetworkActions.changeNetworkSuccess())
  } catch (error) {
    yield put(NetworkActions.changeNetworkError())
  }
}
