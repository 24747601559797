import React from 'react'
import { Box, Snackbar } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { SnackbarActions } from 'store/reducers/snackbar';
import clsx from 'clsx';
import { useStyles } from './styles';


const CustomSnackbar = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const message = useSelector(state => state.snackbar.message)
  const open = useSelector(state => state.snackbar.open)
  const type = useSelector(state => state.snackbar.type)

  const handleClose = () => {
    dispatch(SnackbarActions.hideNotification())
  }

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      message=""
      open={open}
      key={message}
      onClose={handleClose}
      autoHideDuration={5000}
      className={clsx({
        [classes.notification]: true,
        [classes.success]: type === 'success',
        [classes.error]: type === 'error',
      })}
    >
      <Box>{message}</Box>
    </Snackbar>
  )
}

export default CustomSnackbar