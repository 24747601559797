import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { MuiThemeProvider } from '@material-ui/core';
import setDefaultAxios from 'api/axios/configDefaulApi';
import httpService from 'api/axios/http'

import history from 'helpers/history';
import configureStore from 'store/configureStore';

import App from './App';

import theme from './theme/materialTheme';

import './index.scss';
setDefaultAxios()
httpService.setupInterceptors()

const store = configureStore();

// auth Interceptor
// httpService.setupInterceptors(store, history);

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ConnectedRouter history={history}>
        <MuiThemeProvider theme={theme}>
          <App />
        </MuiThemeProvider>
      </ConnectedRouter>
    </Provider>
  </React.StrictMode>,
  document.getElementById('root'),
);
