import React from 'react';
import { Slide } from '@material-ui/core';
import { TransitionProps } from '@material-ui/core/transitions';

const Transition = React.forwardRef<unknown, TransitionProps & { children?: React.ReactElement }>(
  (props, ref) => {
    return <Slide direction="up" ref={ref} {...props} />;
  },
);

export default Transition;
