export const rules = [
  {
    type: 'strategic',
    name: 'Strategic',
    cliff: '8 month cliff',
    unlock: '32 Month Linear Unlock',
  },
  {
    type: 'private_t1',
    name: 'Private T1',
    cliff: '6 month cliff',
    unlock: '24 Month Linear Unlock',
  },
  {
    type: 'private_t2',
    name: 'Private T2',
    cliff: '6 month cliff',
    unlock: '24 Month Linear Unlock',
  },
  {
    type: 'kol',
    name: 'KOL',
    tge: '10% TGE',
    unlock: '10% Monthly Linear Unlock',
  },
  {
    type: 'team',
    name: 'Team',
    cliff: '1 year cliff',
    unlock: '24 Month Linear Unlock',
  },
  {
    type: 'advisory',
    name: 'Advisors',
    cliff: '1 year cliff',
    unlock: '24 Month Linear Unlock',
  },
  {
    type: 'seed',
    name: 'Seed',
    cliff: '1 year cliff',
    unlock: '36 Month Linear Unlock',
  },
  {
    type: 'pre-seed',
    name: 'Pre-Seed',
    cliff: '1 year cliff',
    unlock: '36 Month Linear Unlock',
  },
]

export const BeneficiaryTypeKey = 'beneficiary_type';
