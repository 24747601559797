import { put, call, select } from 'redux-saga/effects';
import WalletConnect from '@walletconnect/client';
import QRCodeModal from '@walletconnect/qrcode-modal';
import web3 from 'helpers/getWeb3';

import { MetaMaskActions } from 'store/reducers/metamask';
import { WalletActions } from '../reducers/wallet';
import { VestingActions } from 'store/reducers/vesting';

declare const window: any;

export function* disconnectWalletSaga() {
  yield call(setLocalSaga, false);
  yield put(MetaMaskActions.setLoading(false));
}

export function* setLocalSaga(connected: boolean) {
  yield localStorage.setItem('connected', JSON.stringify(connected));
}

export function* connectWalletSaga(action: ReturnType<typeof WalletActions.connectWallet>) {
  const { callback } = action.payload;

  yield put(MetaMaskActions.setLoading(true));
  try {
    const accounts: string[] = yield web3.eth.requestAccounts();

    const address = accounts[0];

    if (accounts) {
      yield call(setLocalSaga, true);

      yield put(WalletActions.setWalletRequest({ address }));
      yield put(MetaMaskActions.setLoading(false));

      callback && callback();
    }
  } catch (error) {
    if ((error as any).code === 4001) {
      yield put(WalletActions.disconnectWallet());
    }

    yield put(WalletActions.setWalletFailure());
  }
}

export function* trustConnectSaga() {
  try {
    const connector = new WalletConnect({
      bridge: 'https://bridge.walletconnect.org',
      qrcodeModal: QRCodeModal,
    });

    if (connector.connected) {
      yield connector.killSession();
    }
    yield connector.createSession();

    yield put(WalletActions.trustConnectSuccess(connector));
  } catch (error) {
    yield put(WalletActions.trustConnectError());
    yield put(WalletActions.disconnectWallet());
  }
}

export function* getWalletSaga() {
  const connected = JSON.parse(localStorage.getItem('connected') as string);

  if (window.ethereum && connected) {
    try {
      const accounts: string[] = yield web3.eth.getAccounts();

      if (accounts.length) {
        yield put(WalletActions.setWalletRequest({ address: accounts[0] }));
      } else {
        yield put(WalletActions.setWalletSuccess({ address: '' }));
      }
    } catch (error) {
      yield put(WalletActions.disconnectWallet());
    }
  }
}

export function* setWalletSaga(action: ReturnType<typeof WalletActions.setWalletRequest>) {
  const { address } = action.payload;

  try {
    yield put(WalletActions.setWalletSuccess({ address }));
  } catch (error) {
    yield call(disconnectWalletSaga);
    yield put(WalletActions.disconnectWallet());
  }
}
