import React from 'react';

import { RouteProps, Route as ReactRoute } from 'react-router-dom';
import { Layout, LayoutProps } from '../Layout';

export default function Route(props: RouteProps & Omit<LayoutProps, 'children'>) {
  const { fullwidth, ...rest } = props;

  return (
    <Layout fullwidth={fullwidth}>
      <ReactRoute {...rest} />
    </Layout>
  );
}
