import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { MetaMaskState } from './types';

const initialState: MetaMaskState = {
  isMetaMask: false,
  loading: false,
};

const { actions, reducer } = createSlice({
  name: 'metamask',
  initialState,
  reducers: {
    setIsMetamask: (state, { payload }: PayloadAction<boolean>) => {
      state.isMetaMask = payload;
    },
    setLoading: (state, { payload }: PayloadAction<boolean>) => {
      state.loading = payload;
    },
  },
});

export { actions as MetaMaskActions };

export default reducer;
