import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RewardsState } from './types';

const initialState: RewardsState = {
  loading: false,
  error: false,
  withdrawIndexes: [],
};

const { actions, reducer } = createSlice({
  name: 'rewards',
  initialState,
  reducers: {
    withdrawRewardRequest: (state, action: PayloadAction<{
      address: string;
      claimId: number;
      poolAddress: string
    }>) => {
      state.loading = true
      state.error = false
      state.withdrawIndexes = [...state.withdrawIndexes, action.payload.claimId]
    },
    withdrawRewardResponse: (state, action: PayloadAction<{ claimId: number }>) => {
      state.loading = false
      state.withdrawIndexes = state.withdrawIndexes.filter(
        (item) => item !== action.payload.claimId,
      )
    },
    withdrawRewardError: (state, action: PayloadAction<{ claimId: number }>) => {
      state.loading = false
      state.error = false
      state.withdrawIndexes = state.withdrawIndexes.filter(
        (item) => item !== action.payload.claimId,
      )
    },
  },
});

export { actions as RewardsActions };

export default reducer;