import axios from 'axios'

export const setDefaultAxios = () => {
  axios.defaults.baseURL = `https://api.internal.rmg.io/`
  axios.defaults.headers = {
    'Content-Type': 'application/json',
  }
}

export default setDefaultAxios
