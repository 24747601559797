import React, { PropsWithChildren, ReactNode } from 'react';

import { MenuItem as MenuItemMui } from '@material-ui/core';

import { useStyles } from './styles';

interface MenuItemProps {
  onClick?: () => void;
  disabled?: boolean;
  icon?: ReactNode;
}

const MenuItem = React.forwardRef<HTMLLIElement, PropsWithChildren<MenuItemProps>>(
  (props: PropsWithChildren<MenuItemProps>, ref) => {
    const { onClick, icon, disabled, children } = props;
    const classes = useStyles();

    return (
      <MenuItemMui
        innerRef={ref}
        disabled={disabled}
        onClick={onClick}
        classes={{ root: classes.menuItemRoot, selected: classes.menuItemSelected }}
      >
        {icon && <div className={classes.menuItemIcon}>{icon}</div>}
        <div className={classes.menuItemText}>{children}</div>
      </MenuItemMui>
    );
  },
);

export default MenuItem;
