import React from 'react';

import Routes from './routes';

import './index.scss';
import CustomSnackbar from 'shared/components/StackBar';

function App() {
  return (
    <div className="app">
      <Routes />
      <CustomSnackbar />
    </div>
  );
}

export default App;
