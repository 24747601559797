import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { NetworkState } from './types';

const initialState: NetworkState = {
  loading: false,
  error: false,
  chainId: 0,
};

const { actions, reducer } = createSlice({
  name: 'network',
  initialState,
  reducers: {
    setChainID: (state, action: PayloadAction<{ chainId: number }>) => {
      state.chainId = action.payload.chainId
    },
    changeNetworkRequest: (state, action: PayloadAction<{ chainId: number }>) => {
      state.loading = true
      state.error = false
    },
    changeNetworkSuccess: (state) => {
      state.loading = false
    },
    changeNetworkError: (state) => {
      state.loading = false
      state.error = true
    },
  },
});

export { actions as NetworkActions };

export default reducer;
