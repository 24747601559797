import React from 'react';

import { RouteProps } from 'react-router-dom';

import Route from 'shared/components/Route';
import { LayoutProps } from 'shared/components/Layout';

export default function PublicRoute(props: RouteProps & Omit<LayoutProps, 'children'>) {
  const { ...rest } = props;

  return <Route {...rest} />;
}
