import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TokenState } from './types'

const initialState: TokenState = {
  loading: false,
  error: false,
}

const { actions, reducer } = createSlice({
  name: 'token',
  initialState,
  reducers: {
    addTokenRequest: (state, action: PayloadAction<{ tokenAddress: string; symbol: string }>) => {
      state.loading = true
      state.error = false
    },
    addTokenRequestSuccess: (state) => {
      state.loading = false
    },
    addTokenRequestFailure: (state) => {
      state.loading = false
      state.error = true
    },
  },
});

export { actions as TokenActions };

export default reducer;