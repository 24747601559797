import { makeStyles, Theme } from '@material-ui/core';
import { pinkHover, pink, white } from 'constants/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 70px',
    alignItems: 'center',
    height: 98,
    width: '100%',
    [theme.breakpoints.down('sm')]: {
      padding: '0 20px',
    },
  },
  headerLogo: {
    width: 56,
    cursor: 'pointer',
  },
  headerAddress: {
    fontSize: 16,
    fontFamily: 'Montserrat-bold',
    cursor: 'pointer',
  },
  menu: {
    display: 'flex',
    alignItems: 'center',
  },
  menuList: {
    width: '100%',
    minWidth: 250,
    padding: '10px 0',
  },
  menuItem: {
    padding: 20,
    '&:hover': {
      backgroundColor: pinkHover,
      color: white,
    },
    '& > svg': {
      width: 35,
      height: 'max-content',
      marginRight: 10,
    },
  },
  menuActive: {
    backgroundColor: pink,
    color: white,
    '&:hover': {
      backgroundColor: pinkHover,
      color: white,
    },
  },
  burgerIcon: {
    marginLeft: 10,
    padding: 10,
    '& svg': {
      width: 35,
      height: 35,
    },
  },
  boldText: {
    fontFamily: 'Montserrat-Bold',
  },
  mobileHeader: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 10,
  },
  newTabIcon: {
    width: 10,
  },
}));
