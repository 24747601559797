import React, { useEffect, useState } from 'react';
import {
  Box,
  useMediaQuery,
  SwipeableDrawer,
  IconButton,
  List,
  ListItem,
  Typography,
  Button,
} from '@material-ui/core';
import { Menu as MenuIcon } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import { getAddressLink, sliceAddress } from 'helpers/address';

import { EthIcon, MetamaskIcon, DisconnectIcon } from 'icons';

import { ROUTE_DASHBOARD, ROUTE_HOME, ROUTE_REWARDS } from 'constants/routes';

import { Menu, MenuItem } from 'shared/components/Menu';
import NavLinks from 'shared/components/NavLinks';
import WalletDialog from 'shared/dialogs/Wallet';
import MetaMaskDialog from 'shared/dialogs/MetaMask';

import { WalletActions } from 'store/reducers/wallet';

import logo from 'assets/images/logo.png';

import { useStyles } from './styles';
import { TokenActions } from 'store/reducers/token';
import newTabIcon from 'assets/images/newWindowIcon.jpg';
import { VestingActions } from 'store/reducers/vesting';
import BeneficiaryTypeDialog from 'shared/dialogs/BeneficiaryType';
import { ROUTE_LAUNCHPADS } from '../../../constants/routes';
import { bscNetworks, ethNetworks } from 'constants/network';
import { NetworkActions } from 'store/reducers/network';
import ChangeNetwork from 'shared/dialogs/ChangeNetwork';


export default function Header() {
  const dispatch = useDispatch();
  const classes = useStyles();
  const history = useHistory();
  const location = useLocation();
  const [drawer, setDrawer] = useState<boolean>(false);
  const [walletDialog, setWalletDialog] = useState<boolean>(false);
  const [showMetaMask, setShowMetaMask] = useState<boolean>(false);
  const [networkDialog, setNetworkDialog] = useState<boolean>(false);
  const [tagetNetwork, setTargetNetwork] = useState<string>('');

  const isMobile = useMediaQuery('(max-width:1025px)');
  const {
    wallet: { address },
    metamask: { isMetaMask, loading: metamaskLoading },
  } = useSelector((state) => state);
  const fetchedData = useSelector(state => state.pool.data)
  const { beneficiaryTypeModal } = useSelector(state => state.vesting);
  const { chainId } = useSelector(state => state.network)

  const isConnected = address !== '';

  useEffect(() => {
    if (chainId && !bscNetworks.includes(chainId)) {
      setTargetNetwork('BSC')
      setNetworkDialog(true)
    }
  }, [chainId, location.pathname])

  const handleDisconnect = () => {
    dispatch(WalletActions.disconnectWallet());
    handleCloseWalletDialog();
  };

  const handleCloseWalletDialog = () => {
    setWalletDialog(false);
  };

  const onViewBlockchain = () => {
    window.open(getAddressLink(fetchedData?.rewardToken || ''));
  };

  const toggleDrawer = (open: boolean) => {
    setDrawer(open);
  };

  const handleOpenMetaMask = () => {
    setShowMetaMask(true);
  };

  const handleCloseMetaMask = () => {
    setShowMetaMask(false);
  };

  const handleMetaMaskButton = () => {
    if (!isMetaMask) {
      return window.open('https://metamask.io/download.html', '_blank');
    }

    dispatch(
      WalletActions.connectWallet({
        callback: () => {
          handleCloseMetaMask();
          history.push(ROUTE_HOME);
        },
      }),
    );
  };

  const handleAddToMetamask = () => {
    dispatch(TokenActions.addTokenRequest({
      tokenAddress: fetchedData?.rewardToken || '',
      symbol: fetchedData?.rewardTokenSymbol || '',
    }))
  }

  let navLinks = [
    { id: 0, title: 'Overview', path: ROUTE_HOME },
    // { id: 1, title: 'Dashboard LaunchPads' , path: ROUTE_LAUNCHPADS },
    // { id: 2, title: 'Dashboard Investments', path: ROUTE_DASHBOARD },
    { id: 3, title: 'Rewards', path: ROUTE_REWARDS },
    {
      id: 4,
      title: 'Swap',
      path: 'https://pancakeswap.finance/add/0x6Bcd897D4BA5675F860C7418ddc034f6c5610114/BNB',
      isTargetBlank: true,
      tooltip: 'Swap on PancakeSwap',
    },
  ];

  const menuItems = [
    {
      id: 5,
      icon: () => <MetamaskIcon />,
      onClick: handleAddToMetamask,
      tooltip: 'Add to metamask',
    },
    {
      id: 6,
      icon: () => <EthIcon />,
      onClick: onViewBlockchain,
      tooltip: 'View on BSCscan',
      isTargetBlank: true,
    },
  ]

  const handleTrustClick = () => {
    handleCloseMetaMask();

    dispatch(WalletActions.trustConnect())
  }

  const handleCloseBeneficiaryModal = () => {
    dispatch(VestingActions.toggleBeneficiaryTypeModal({ isOpen: false }))
  }

  const handleAddBeneficiary = (type: string) => {
    dispatch(VestingActions.addBeneficiaryRequest({ address, type: location.pathname.includes('launchpads') ? 'launchpads' : 'kol' }))
  }

  const handleChangeNetwork = () => {
    dispatch(NetworkActions.changeNetworkRequest({
      chainId: bscNetworks[0],
    }));
  }

  const handleCloseNetworkDialog = () => {
    setNetworkDialog(false)
  }

  return (
    <div className={classes.header}>
      <img
        src={logo}
        alt="logo"
        className={classes.headerLogo}
        onClick={() => history.push(ROUTE_HOME)}
      />
      {isMobile ? (
        <Box className={classes.mobileHeader}>
          <Button
            variant="outlined"
            onClick={isConnected ? () => setWalletDialog(true) : handleOpenMetaMask}
          >
            {isConnected ? sliceAddress(address) : 'Connect wallet'}
          </Button>
          <IconButton
            className={classes.burgerIcon}
            aria-label="back"
            size="small"
            onClick={() => toggleDrawer(true)}
          >
            <MenuIcon />
          </IconButton>
          <SwipeableDrawer
            open={drawer}
            anchor="right"
            onClose={() => toggleDrawer(false)}
            onOpen={() => toggleDrawer(true)}
          >
            <List className={classes.menuList}>
              {navLinks.map(({ id, title, path, isTargetBlank }) => {

                return (
                  <ListItem
                    button
                    key={id}
                    className={classes.menuItem}
                    onClick={() => {
                      isTargetBlank ? window.open(path) : history.push(path);
                      toggleDrawer(false);
                    }}
                  >
                    <Typography variant="subtitle2" className={classes.boldText}>
                      {title} {isTargetBlank && <img className={classes.newTabIcon} src={newTabIcon} alt='newTab'/>}
                    </Typography>
                  </ListItem>
                );
              })}
              {menuItems.map(({ id, icon, onClick, tooltip, isTargetBlank }) => {
                return (
                  <ListItem key={id} onClick={onClick} className={classes.menuItem}>
                    {icon()}
                    <Typography variant="subtitle2" className={classes.boldText}>
                      <Box>
                        {tooltip} {isTargetBlank && <img className={classes.newTabIcon} src={newTabIcon} alt='newTab'/>}
                      </Box>
                    </Typography>
                  </ListItem>
                )
              })}
            </List>
          </SwipeableDrawer>
        </Box>
      ) : (
        <Box className={classes.menu}>
          <NavLinks navLinks={navLinks} menuItems={menuItems}/>
          <Menu
            action={isConnected && history.location.pathname !== '/' ? 'typography' : 'profile'}
            align="right"
          >
            <MenuItem icon={<DisconnectIcon />} onClick={handleDisconnect}>
              Disconnect
            </MenuItem>
          </Menu>
        </Box>
      )}
      <MetaMaskDialog
        open={showMetaMask}
        isMetaMask={isMetaMask}
        handleClose={handleCloseMetaMask}
        loading={metamaskLoading}
        handleButton={handleMetaMaskButton}
        handleTrustClick={handleTrustClick}
      />
      <WalletDialog
        open={walletDialog}
        handleClose={handleCloseWalletDialog}
        handleButton={handleDisconnect}
      />
      <BeneficiaryTypeDialog
        open={beneficiaryTypeModal}
        handleClose={handleCloseBeneficiaryModal}
        handleButton={handleAddBeneficiary}
      />
      <ChangeNetwork
        open={networkDialog}
        handleClose={handleCloseNetworkDialog}
        handleButton={handleChangeNetwork}
        targetNetwork={tagetNetwork}
      />
    </div>
  );
}
