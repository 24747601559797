import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EstiamtedPriceState } from './types'

const initialState: EstiamtedPriceState = {
  rainPrice: 1,
  lpPrice: 1,
  loading: false,
  error: false,
}

const { actions, reducer } = createSlice({
  name: 'estimatedPrice',
  initialState,
  reducers: {
    getEstimatedPriceRequest: (state) => {
      state.loading = true;
      state.error = false;
    },
    getEstimatedPriceSuccess: (state, {
      payload: {
        rainPrice,
        lpPrice,
      },
    }: PayloadAction<{ rainPrice: number; lpPrice: number; }>) => {
      state.rainPrice = rainPrice;
      state.lpPrice = lpPrice;
      state.loading = false;
      state.error = false;
    },
    getEstimatedPriceFailure: (state) => {
      state.loading = false;
      state.error = true;
    },
  },
});

export { actions as EstimatedPriceActions };

export default reducer;