import React, { useEffect } from 'react';
import clsx from 'clsx';
import Header from '../Header';

import { useStyles } from './styles';
import { useLocation } from 'react-router-dom';

export interface LayoutProps {
  children: any;
  fullwidth?: boolean;
}

export const Layout = (props: LayoutProps) => {
  // Props
  const { children, fullwidth } = props;

  const { pathname } = useLocation();
  const classes = useStyles();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return (
    <div className={classes.root}>
      <Header />
      <div className={clsx(classes.main, { [classes.container]: !fullwidth })}>{children}</div>
    </div>
  );
};

Layout.defaultProps = {
  fullwidth: false,
};
