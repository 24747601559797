import { rgba } from 'polished';

// Main
export const white = '#FFFFFF';
export const black = '#000000';

// Primary
export const cerulean = '#00aeef';
export const pink = '#ec008c';
export const pinkHover = '#bb006f';
export const gallery = '#f0f0f0';
export const alto = '#cecece';
export const athensGray = '#f2f2f2';
export const monza = '#DC0020';
export const mercury = '#e9e9e9';
export const mineShaft = '#292929';
export const logan = '#e3e7ed';

// Secondary

export const lightGrey = '#f0f0f0';
export const blueColor = '#008abd';
export const alertBG = '#e5f6fd';
export const gallery2 = '#ebebeb';

const colors = {
  primary: pink,
  secondary: cerulean,
  background: athensGray,

  textPrimary: black,
  textSecondary: white,

  // Buttons
  containedPrimaryBtnDisabledBg: alto,
  containedPrimaryBtnDisabledText: white,
  containedSecondaryBtnDisabledBg: alto,
  containedSecondaryBtnDisabledText: alto,
  containedBtnText: white,
  containedBtnBg: pink,
  containedBtnHoverBg: pinkHover,
  outlinedBtnText: pink,
  outlinedBtnBg: white,
  outlinedBtnHoverBg: pinkHover,
  primaryBtnBg: rgba(pink, 0.95),
  primaryBtnHoverBg: rgba(pink, 0.9),
  secondaryBtnBg: cerulean,
  secondaryBtnText: white,
  secondaryBtnHoverBg: rgba(cerulean, 0.9),
  secondaryLargeBtnHoverText: white,
  outlinedBtnDisabledBorder: rgba(pink, 0.4),
  outlinedBtnDisabledText: rgba(pink, 0.6),
  outlinedPrimaryBtnText: pink,
  outlinedPrimaryBtnBorder: pink,
  outlinedPrimaryBtnHoverBg: pinkHover,
  outlinedPrimaryBtnHoverBorder: pink,
  outlinedPrimaryBtnHoverText: white,
  outlinedSecondaryBtnHoverBg: pink,
  outlinedSecondaryBtnHoverText: white,
  outlinedSecondaryBtnDisabledBorder: alto,
  outlinedSecondaryBtnDisabledText: white,

  // Divider
  backgroundColor: gallery,

  // Form
  inputPlaceholder: alto,
  inputBorder: alto,
  inputBorderHover: logan,
  inputBorderFocused: logan,
  inputBorderError: monza,
  inputHelperError: monza,
  inputDisabledBorder: mercury,
  inputDisabledText: mercury,
  selectOption: mineShaft,
  selectOptionHover: pinkHover,

  // Table
  tableBorder: lightGrey,
};

export default colors;
