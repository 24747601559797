import axios from 'axios'

const httpService = {
  setupInterceptors: () => {
    axios.interceptors.request.use(
      (config: any) => {
        config.headers['Content-Type'] = 'application/json'
        return config
      },
      (error) => error,
    )
    axios.interceptors.response.use(
      (response) => {
        // simply return the response if there is no error

        return response
      },
      (error) => {
        if (error.response.status === 403) {
          return Promise.reject(error)
        }
        return Promise.reject(error)
      },
    )
  },
}
export default httpService
