import { createTheme } from '@material-ui/core';
import colors, { alto, cerulean, lightGrey, white } from 'constants/colors';

const theme = createTheme({
  typography: {
    fontFamily: 'Montserrat-Regular, sans-serif',
  },
  palette: {
    primary: {
      main: colors.primary,
    },
    secondary: {
      main: colors.secondary,
    },
    text: {
      primary: colors.textPrimary,
      secondary: colors.textSecondary,
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1024,
      xl: 1440,
    },
  },
  overrides: {
    MuiTypography: {
      h1: {
        fontSize: 48,
        fontFamily: 'Montserrat-Bold',
        lineHeight: 1.17,
      },
      h2: {
        fontSize: 38,
        fontFamily: 'Montserrat-Bold',
        lineHeight: 1.32,
      },
      h3: {
        fontSize: 22,
        fontFamily: 'Montserrat-Bold',
        lineHeight: 1.33,
      },
      h4: {
        fontSize: 18,
        fontFamily: 'Montserrat-Bold',
        lineHeight: 1.33,
      },
      subtitle1: {
        fontSize: 18,
        lineHeight: 1.44,
        fontFamily: 'Montserrat-Medium',
      },
      subtitle2: { fontSize: 16 },
      body1: {
        fontSize: 16,
        lineHeight: 1.44,
        fontFamily: 'Montserrat-Medium',
      },
      body2: {
        fontFamily: 'Montserrat-Regular',
        fontSize: 14,
      },
      caption: {
        fontFamily: 'Montserrat-Regular',
        fontSize: 12,
      },
    },

    MuiTooltip: {
      tooltip: {
        fontSize: 15,
      },
    },

    MuiStepper: {
      root: {
        padding: 0,
      },
    },
    MuiStep: {
      horizontal: {
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiStepLabel: {
      labelContainer: {
        cursor: 'pointer',
      },
      iconContainer: {
        display: 'none',
      },
    },

    MuiDialogTitle: {
      root: {
        padding: 0,
      },
    },
    MuiDialogContent: {
      root: {
        padding: 0,
        '&:first-child': {
          paddingTop: 0,
        },
      },
    },
    MuiDialogActions: {
      root: {
        width: '100%',
        justifyContent: 'center',
        padding: 0,
      },
    },
    MuiBackdrop: {
      root: {
        background: 'rgba(157,155,165,.2)',
        backdropFilter: 'blur(20px)',
      },
    },
    MuiDialog: {
      paperWidthMd: {
        maxWidth: 822,
      },
      paperWidthSm: {
        maxWidth: 600,
      },
      paperWidthXs: {
        maxWidth: 400,
      },
      paper: {
        width: '100%',
        alignItems: 'center',
      },
    },

    MuiCard: {
      root: {
        overflow: 'inherit',
        '&:hover': {
          boxShadow: '0 10px 32px rgb(0 0 0 / 28%)',
        },
      },
    },

    MuiDivider: {
      vertical: {
        backgroundColor: colors.backgroundColor,
      },
      root: {
        backgroundColor: colors.backgroundColor,
      },
    },

    MuiCardContent: {
      root: {
        padding: 0,
      },
    },

    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
    MuiListItem: {
      gutters: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    MuiListSubheader: {
      root: {
        fontSize: 18,
        fontFamily: 'Montserrat-Bold',
        lineHeight: 1.33,
        margin: '16px 0 8px 0',
        color: colors.primary,
      },
    },
    MuiAppBar: {
      root: {
        height: 80,
      },
      colorPrimary: {
        backgroundColor: white,
      },
    },

    MuiPaper: {
      elevation1: {
        boxShadow: `0 2px 30px 0 rgba(0, 0, 0, 0.06)`,
      },
      elevation2: {
        boxShadow: `0 6px 23px 0 rgba(118, 117, 121, 0.15)`,
      },
      elevation4: {
        boxShadow: `0 6px 23px 0 rgba(118, 117, 121, 0.1)`,
      },
      rounded: {
        borderRadius: 20,
      },
    },

    MuiTableCell: {
      root: {
        borderBottom: 'none',
      },
    },
    MuiTableHead: {
      root: {
        borderBottom: `1px solid ${colors.tableBorder}`,
      },
    },

    MuiButton: {
      label: {
        whiteSpace: 'nowrap',
      },
      root: {
        borderRadius: 25,
        fontFamily: 'Montserrat-Bold',
        padding: '13px 24px',
        height: 50,
        textTransform: 'capitalize',
        fontSize: '18px',
        lineHeight: 1.44,
        '&.icon-outlined': {
          '& svg': {
            fontSize: '2.5rem',
          },
        },
      },
      contained: {
        boxShadow: 'none',
        backgroundColor: colors.primaryBtnBg,
        color: colors.containedBtnText,
        '&:active': {
          boxShadow: 'none',
        },
        '&:hover': {
          backgroundColor: colors.containedBtnHoverBg,
        },
        '&.Mui-disabled': {
          backgroundColor: colors.containedPrimaryBtnDisabledBg,
          color: colors.containedPrimaryBtnDisabledText,
        },
      },

      outlined: {
        border: `2px solid ${colors.outlinedPrimaryBtnBorder}`,
        borderColor: colors.outlinedPrimaryBtnBorder,
        padding: '13px 24px',
        color: colors.primary,
        '&:active': {
          boxShadow: 'none',
        },
        '&:hover': {
          backgroundColor: colors.outlinedSecondaryBtnHoverBg,
          color: colors.outlinedPrimaryBtnHoverText,
        },
        '&.Mui-focused': {
          borderColor: 'green',
        },
        '&.Mui-disabled': {
          color: colors.outlinedBtnDisabledText,
          border: `2px solid ${colors.outlinedBtnDisabledBorder}`,
        },
      },
      containedPrimary: {
        backgroundColor: colors.primaryBtnBg,
        '&:hover': {
          backgroundColor: colors.primaryBtnHoverBg,
        },
      },
      containedSecondary: {
        backgroundColor: colors.secondaryBtnBg,
        color: colors.secondaryBtnText,
        '&:hover': {
          backgroundColor: colors.secondaryBtnHoverBg,
        },
      },
      outlinedPrimary: {
        color: colors.outlinedPrimaryBtnText,
        borderColor: colors.outlinedPrimaryBtnBorder,
        '&:hover': {
          backgroundColor: colors.outlinedPrimaryBtnHoverBg,
          color: colors.outlinedPrimaryBtnHoverText,
          borderColor: colors.outlinedPrimaryBtnHoverBorder,
        },
      },
      outlinedSecondary: {
        '&:hover': {
          backgroundColor: colors.outlinedSecondaryBtnHoverBg,
          color: colors.outlinedSecondaryBtnHoverText,
        },
        '&.Mui-disabled': {
          borderColor: colors.outlinedSecondaryBtnDisabledBorder,
          color: colors.outlinedSecondaryBtnDisabledText,
        },
      },
    },

    // INPUT
    MuiFormHelperText: {
      root: {
        fontSize: 12,
        fontWeight: 500,
        '&.Mui-error': {
          color: colors.inputHelperError,
        },
      },
    },
    MuiInput: {
      underline: {
        '&:hover:not(.Mui-disabled):before': {
          borderBottom: `1px solid ${colors.inputBorderHover}`,
        },
        '&:after, &:before': {
          borderBottom: `1px solid ${colors.inputBorder}`,
        },
      },
    },
    MuiInputBase: {
      root: {
        '&.Mui-error input, &.Mui-error textarea, &.Mui-error .MuiSelect-select': {
          borderColor: colors.inputBorderError,
          fontSize: 16,
        },
      },
      input: {
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: 500,
        height: 'auto',
        padding: '6px 0 6px 0',
        boxSizing: 'border-box',
        marginTop: '16px',
        '&::-webkit-input-placeholder': {
          color: colors.inputPlaceholder,
          opacity: 1,
        },
        '&:focus': {
          borderColor: colors.inputBorderFocused,
        },
        '&.Mui-disabled': {
          borderColor: colors.inputDisabledBorder,
          color: colors.inputDisabledText,
        },
        '&::-webkit-outer-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '&::-webkit-inner-spin-button': {
          '-webkit-appearance': 'none',
          margin: 0,
        },
        '-webkit-appearance': 'none',
      },
      inputMarginDense: {
        paddingTop: '1.3rem',
      },
      multiline: {
        padding: 0,
      },
      inputMultiline: {
        padding: '6px 0 6px 0',
      },
    },
    MuiSvgIcon: {
      root: {
        width: 24,
        height: 24,
      },
    },
    MuiTabs: {
      root: {
        height: 50,
      },
      indicator: {
        display: 'none',
      },
    },
    MuiTab: {
      root: {
        height: 50,
        fontSize: 16,
        textTransform: 'capitalize',
        fontFamily: 'Montserrat-Bold',
        border: `2px solid ${lightGrey}`,
        '&:first-child': {
          borderBottomLeftRadius: 25,
          borderTopLeftRadius: 25,
        },
        '&:last-child': {
          borderBottomRightRadius: 25,
          borderTopRightRadius: 25,
        },
      },
      selected: {},
    },
    MuiTextField: {
      root: {
        width: '100%',
        border: `1px solid ${alto}`,
        borderRadius: 10,
        '&::before': {
          borderBottom: 'none',
        },
      },
    },
    MuiSlider: {
      valueLabel: {
        '& > span > span': {
          fontSize: 15,
        },
      },
      root: {
        color: cerulean,
      },
      rail: {
        height: 8,
      },
      thumb: {
        height: 16,
        width: 16,
      },
      track: {
        height: 8,
      },
    },
  },
});

export default theme;
