import { makeStyles, Theme } from '@material-ui/core';
import { red } from '@material-ui/core/colors';
import { pinkHover, pink, white, monza } from 'constants/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  notification: {
    color: white,
    background: pink,
    '&:hover': {
      background: pinkHover,
    },
    height: 50,
    display: 'flex',
    alignItems: 'center',
    padding: '0 20px',
    borderRadius: 15,
    fontWeight: 'bold',
  },
  success: {},
  error: {
    background: monza,
  },
}));
