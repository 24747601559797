// export const LIQUIDITY_MANAGER_CONTRACT = '0x62e98aCFa69c8D1FC8fE6caF5cF203A3cC84EaE7';
// export const ESCROW_POOL_CONTRACT = '0x9Ad7CF7EC24014046db906891EB908B384049419';
export const VIEW_CONTRACT = '0xc75538ac6dc65b35594e4d97a82791407b232ef8'; //MAINNET
export const VESTING_CONTRACT = { //MAINNET
  strategic: '',
  private_t1: '',
  private_t2: '',
  kol: '0xde46cF01561B68b4DF4F32502768aF47240DDdde',
  team: '',
  advisory: '',
  seed: '0x96f19828529bFEf13e6aC64DE952cedA2d866f2f',
  'pre-seed': '0x0463a3CB065a51953bf51c45f48290D68AA1407D',
  launchpads: '0x0c415EdEE224D5f5a2292D739f1a3D3A01C8C69E',
}
// export const VIEW_CONTRACT = '0xc56202638C9172b421f330c7A1E97Aed669389Cf'; //KOVAN
// export const VESTING_CONTRACT = { //KOVAN
//   strategic: '',
//   private_t1: '',
//   private_t2: '',
//   kol: '0xc1c8a0B09f8c5F6E9D5AF32A616da5C1530BEdFF',
//   team: '',
//   advisory: '',
//   seed: '0x96f19828529bFEf13e6aC64DE952cedA2d866f2f',
//   'pre-seed': '0x0463a3CB065a51953bf51c45f48290D68AA1407D',
//   launchpads: '0x87F2246df31Afa331Cd245EFd233BDcc30E64399',
// }


