import { makeStyles, Theme } from '@material-ui/core';
import colors, { black } from 'constants/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  linksContainer: {
    height: '100%',
    display: 'flex',
    width: 'min-content',
    [theme.breakpoints.down('xs')]: {
      width: '100%',
      flexDirection: 'column',
    },
    [theme.breakpoints.between('sm', 'md')]: {
      order: 3,
      gridRowStart: 2,
      gridColumn: '1/3',
      '& > li:first-child': {
        paddingLeft: 0,
      },
    },
  },

  disableLink: {
    '&:hover': {
      color: `${black} !important`,
    },
    '&.active:after': {
      backgroundColor: 'transparent !important',
    },
  },

  link: {
    fontFamily: 'Montserrat-Bold',
    position: 'relative',
    color: black,
    whiteSpace: 'nowrap',
    fontSize: 16,
    '&.active:after': {
      backgroundColor: colors.primary,
    },
    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: -4,
      borderRadius: 7,
      left: 0,
      right: 0,
      height: 3,
    },
    '&:hover': {
      color: colors.primary,
    },
  },
  menuItem: {
    cursor: 'pointer',
    '& > svg': {
      width: 35,
      height: 'max-content',
    },
  },
  newTabIcon: {
    width: 10,
  },
}));
