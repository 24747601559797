import React, { FC } from 'react';
import { NavLink } from 'react-router-dom';
import { List, ListItem, Tooltip, Typography } from '@material-ui/core';
import newTabIcon from 'assets/images/newWindowIcon.jpg';
import { useStyles } from './styles';

interface NavLinksProps {
  navLinks: {
    id: number;
    title: string;
    path: string;
    isTargetBlank?: boolean;
    tooltip?: string;
  }[];
  menuItems: {
    id: number;
    icon: () => JSX.Element;
    tooltip: string;
    onClick: () => void;
    isTargetBlank?: boolean;
  }[];
}

const NavLinks: FC<NavLinksProps> = ({ navLinks, menuItems }) => {
  const classes = useStyles();

  return (
    <List className={classes.linksContainer}>
      {navLinks.map(({ title, path, isTargetBlank, tooltip }) => {
        return (
          <ListItem key={path}>
            <NavLink
              to={{ pathname: path }}
              className={classes.link}
              target={isTargetBlank ? '_blank' : '_self'}
              isActive={(match) => {
                if (!match) return false

                return match.url === path
              }}
            >
              {tooltip ? (
                <Tooltip title={tooltip}>
                  <Typography className={classes.link}>{title} {isTargetBlank && <img className={classes.newTabIcon} src={newTabIcon} alt='newTab'/>}</Typography>
                </Tooltip>
              ) : title}
            </NavLink>
          </ListItem>
        );
      })}
      {menuItems.map(({ id, icon, onClick, tooltip }) => {
        return (
          <ListItem key={id} onClick={onClick} className={classes.menuItem}>
            <Tooltip title={tooltip} placement="top-start">
              {icon()} 
            </Tooltip>
          </ListItem>
        )
      })}
    </List>
  );
};

export default NavLinks;
