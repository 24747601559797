import React, { useState } from 'react';
import { Dialog, DialogContent, Typography, Box, Button, DialogTitle, CircularProgress } from '@material-ui/core';
import { Check, HighlightOff } from '@material-ui/icons';

import { CloseIcon } from 'icons';
import { useDialogStyles } from '../styles';
import { useSelector } from 'react-redux';

interface MetaMaskProps {
  open: boolean;
  handleClose(): void;
  handleButton(): void;
  targetNetwork: string;
}

const ChangeNetwork = ({ open, targetNetwork, handleClose, handleButton }: MetaMaskProps) => {
  const loading = useSelector(state => state.network.loading);
  const error = useSelector(state => state.network.error);

  const [clicked, setClicked] = useState<boolean>(false)

  const dialogClasses = useDialogStyles();
  const title = 'Wrong network';
  const description = `In order to continue, please switch to a ${targetNetwork} network.`;
  const text = 'Confirm network change in your wallet';

  let buttonLabel = `Change network to ${targetNetwork}`;

  let stakeIcon = <CircularProgress />

  if (loading) {
    buttonLabel = 'In Progress...';
  } else if (!loading && !error && clicked) {
    buttonLabel = 'Done';
    stakeIcon = <Check color='primary' />
  } else if (!loading && error) {
    // text = 'Your address is not beneficiary by the selected type';
    stakeIcon = <HighlightOff color='primary' />
  }

  const handleSubmit = () => {
    if (!loading && !error && clicked) {
      handleClose()
      return
    }

    setClicked(true)
    handleButton()
  }

  return (
    <Dialog open={open} onClose={handleClose} className={dialogClasses.processDialogs}>
      <Box mt={2} mb={4}>
        <DialogTitle className={dialogClasses.dialogTitle}>
          <Typography variant='h3'>{title}</Typography>
          <Box className={dialogClasses.closeIcon} onClick={handleClose}>
            <CloseIcon />
          </Box>
        </DialogTitle>
        <DialogContent className={dialogClasses.proceesContent}>
          <Box mt={3}>
            <Typography variant="subtitle1">{description}</Typography>
          </Box>
          {((loading || error) || (!loading && !error && clicked)) && (
            <Box display="flex" alignItems="center">
              <Box className={dialogClasses.processIcon}>
                {stakeIcon}
              </Box>
              <Box mt={2} mb={3} ml={3.5}>
                <Typography className={dialogClasses.processText}>{text}</Typography>
              </Box>
            </Box>
          )}
          <Box mb={3} mt={3}>
            <Button onClick={handleSubmit} variant="contained" disabled={loading}>
              {buttonLabel}
            </Button>
          </Box>
        </DialogContent>
      </Box>
    </Dialog>
  );
};

export default ChangeNetwork;
