export const ethNetworks = [1, 42]
export const bscNetworks = [56, 97]

export const networkConstants = {
  56: {
    name: 'BNB',
    symbol: 'BNB',
    chainId: '0x38',
    chainName: 'Binance Smart Chain',
    decimals: 18,
    blockExplorerUrls: ['https://bscscan.com'],
    rpcUrls: ['https://bsc-dataseed.binance.org'],
  },
  97: {
    name: 'BNB',
    symbol: 'BNB',
    chainId: '0x61',
    chainName: 'Binance Smart Chain',
    decimals: 18,
    blockExplorerUrls: ['https://testnet.bscscan.com'],
    rpcUrls: ['https://data-seed-prebsc-2-s2.binance.org:8545'],
  },
};
