import { makeStyles, Theme } from '@material-ui/core';

import colors, { athensGray } from 'constants/colors';

export const useStyles = makeStyles((theme: Theme) => ({
  // MENU
  profile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',

    padding: '2px 2px 2px 16px',
    marginLeft: '8px',

    borderRadius: '30px',
    boxSizing: 'content-box',
    border: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  price: {
    paddingRight: '8px',
    textAlign: 'end',
  },
  eth: {
    fontWeight: 'bold',
  },
  buttonMedium: {
    padding: 0,
    width: 50,
    height: 50,
    minWidth: 50,
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
  },
  buttonSmall: {
    padding: 0,
    width: 32,
    height: 32,
    minWidth: 32,
    border: `2px solid ${theme.palette.secondary.main}`,
    borderRadius: '50%',
  },
  iconMedium: {
    width: 32,
    height: 32,
    fontSize: 32,
  },
  iconSmall: {
    width: 24,
    height: 24,
    fontSize: 24,
  },
  paper: {
    borderRadius: 8,
    boxShadow: '0 -4px 22px 0 rgba(118, 117, 121, 0.15)',
  },
  list: {
    padding: '8px 0',
  },

  // MENU ITEM
  menuItemRoot: {
    display: 'flex',
    gridGap: 24,
    gridTemplateColumns: '32px 1fr',
    alignItems: 'center',
    padding: '12px 24px',
    transition: 'color .2s',
    '&:hover': {
      background: athensGray,
    },
    '&:focus': {
      background: 'transparent',
      color: theme.palette.secondary.main,
    },
  },
  menuItemSelected: {
    background: 'transparent !important',
    color: theme.palette.secondary.main,
  },
  menuItemIcon: {
    width: 32,
    height: 32,
    '& svg': { width: 32, height: 32 },
  },
  menuItemText: {
    fontSize: 18,
    fontWeight: 'bold',
    lineHeight: 1.78,
  },
  connectBtnPadding: { padding: '13px 12px 13px 24px' },
  connectBtn: {
    minWidth: 160,
    marginLeft: 20,
    '& svg': {
      marginLeft: 8,
    },
  },
  headerAddress: {
    fontSize: 16,
    fontFamily: 'Montserrat-bold',
  },
  addressWrapper: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    marginLeft: 45,
  },
}));
